$(function () {
  var nav = $('.main-nav');
  var navSections = $('.mnav-section')

  // main nav
  $('.menu-links').hoverIntent({
    selector: '.js-has-subnav',
    over: function showSubnav() {
      //console.log('over');
      if (window.innerWidth > 1200) {
        $(this).addClass('has-subnav--active');
      }
    },
    out: function hideSubnav() {
      //console.log('and out');
      if (window.innerWidth > 1200) {
        $(this).removeClass('has-subnav--active');
      }
    },
    timeout: 400
  });

  $(".js-has-subnav").on('focusin', function () {
    console.log("focusin");
    var links = $(".js-has-subnav");
    for (var i = 0; i < links.length; i++) {
      if (this !== links[i] && $(links[i]).hasClass('has-subnav--active')) {
        $(links[i]).removeClass('has-subnav--active');
      }
    }
    if (window.innerWidth > 1200) {
      $(this).addClass('has-subnav--active');
    }
  });

  document.getElementById('menu-toggle-close').addEventListener('click', function () {
    // toggle mobile menu
    document.getElementById('menu-toggle').checked = !document.getElementById('menu-toggle').checked;
    $('body').toggleClass('mnav-active', document.getElementById('menu-toggle').checked);
  });

  $('.main-menu').on('touchend', '.has-subnav .top-level-link', function toggleSubnav(e) {
    var link = $(this);
    var menuLinks = $('.menu-links');
    if ($($(this).siblings()[0]).css('display') === 'none') {
      e.preventDefault()
      var menuLinks = $('.menu-links');

      if (menuLinks.data('active-menu') !== link.attr('href')) {
        menuLinks.find('.has-subnav').removeClass('has-subnav--active');
      }

      toggle($(this), '.has-subnav', 'has-subnav--active');
      menuLinks.data('active-menu', link.attr('href'));
    }
  });

  $('.main-nav-top').on('change', '.menu-toggle', function activateMenuSection(e) {
    var cb = $(this);
    var mnavActive = cb.prop('checked');
    $('body').toggleClass('mnav-active', mnavActive);

    if (nav.data('current-section')) {
      if (nav.data('current-section') === cb.attr('id')) {
        toggle($(this), '.mnav-section', 'mnav-section--active');
      }
      else {
        navSections.find('#' + nav.data('current-section')).prop('checked', false);
        navSections.removeClass('mnav-section--active');
        cb.parents('.mnav-section').addClass('mnav-section--active');
      }
    }
    else {
      toggle(cb, '.mnav-section', 'mnav-section--active');
    }
    nav.data('current-section', cb.attr('id'));
  });

  function toggle(el, parentSel, cssClass) {
    var p = el.parents(parentSel) || el.parents().eq(0);
    el.parents(parentSel).toggleClass(cssClass || 'active');
  }

  // Toggle footer menu on mobile view
  var children = document.querySelectorAll(".footer-section-heading");

  children.forEach(function (child) {
    child.addEventListener("click", function () {
      var sibling = this.nextElementSibling;
      if (sibling.classList.contains("expanded")) {
        this.classList.remove("expanded");
        sibling.classList.remove("expanded");
      } else {
        this.classList.add("expanded");
        sibling.classList.add("expanded");
      }
    }, { passive: true });
  });

  setTimeout(function(){
    var worker = new Worker('/dist/rsp/js/worker.js');
    worker.postMessage([localStorage.getItem("bpData"), localStorage.getItem("resultsConfig")]);
    worker.addEventListener('message', function (event) {
      if (window.localStorage && event.data.length > 0) {
        try {
          localStorage.setItem(
            "bpData",
            JSON.stringify({
              timestamp: Date.now(),
              products: event.data,
            })
          );
        }
        catch (error) {
          console.warn('Error while saving to localstorage', error);
        }
      }
    }, { passive: true });
  }, 0);
});